<template>
  <section>
    <b-row>
      <b-col cols="12">
        <div>
          <b-card>
            <b-row>
              <b-col md="4" lg="4" xs="12">
                <v-select
                  v-model="userIdOption"
                  :options="selectUserIdOption"
                  :reduce="(option) => option.id"
                  v-on:input="getUserTaskInfo"
                  placeholder="Select Board Member"
                  label="name"
                  class="custom-font"
                >
                  <template #option="data">
                    <UserSelect :user="data" />
                  </template>
                </v-select>
              </b-col>

              <b-col md="4" lg="4" xs="12">
                <v-select
                  v-model="taskIdOption"
                  :options="selectTaskIdOptions"
                  :reduce="(option) => option.id"
                  v-on:input="filterByTask"
                  placeholder="Select Task"
                  label="name"
                  class="custom-font"
                >
                </v-select>
              </b-col>
              <b-col md="4" lg="4" xs="12">
                <v-select
                  v-model="subTaskIdOption"
                  :options="selectSubTaskIdOptions"
                  :reduce="(option) => option.id"
                  v-on:input="filterBySubTask"
                  placeholder="Select Sub Task"
                  label="name"
                  class="custom-font"
                >
                </v-select>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-col>
      <b-col cols="12">
        <b-card title="Phase Duration Log">
          <template v-if="phaseChangeLog.length > 0">
            <app-timeline>
              <!-- 12 INVOICES HAVE BEEN PAID -->
              <app-timeline-item
                v-for="(log, index) in phaseChangeLog"
                :key="index"
                :variant="setLogVariant(log)"
              >
                <div
                  class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                >
                  <h6 v-html="formatLogTitle(log)"></h6>
                  <small class="text-muted">{{
                    formatDateTime(log?.created_at)
                  }}</small>
                </div>
                <p
                  v-html="
                    formatLogDescription(
                      log?.created_at,
                      nextPhaseChangeStartTime[index]
                    )
                  "
                ></p>
              </app-timeline-item>
            </app-timeline>
          </template>
          <template v-else>
            <app-timeline variant="primary">
              <app-timeline-item>
                <div
                  class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                >
                  <h6>No Task or Sub Task Filters Selected</h6>
                </div>
              </app-timeline-item>
            </app-timeline>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BLink,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BButton,
  BForm,
  BModal,
  BCol,
  BRow,
  BCardText,
  BFormTextarea,
  BSpinner,
  BAlert,
  VBTooltip,
  BFormDatepicker,
  BTabs,
  BTab,
  BImg,
  BMedia,
  BAvatarGroup,
  BEmbed,
  BDropdownDivider,
  BButtonGroup,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import { VueGoodTable } from "vue-good-table";
import { quillEditor } from "vue-quill-editor";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { required, max, size, ext, max_value, min_value } from "@validations";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import { kFormatter } from "@core/utils/filter";
import { priorityOptionConstants } from "@/helpers/constant/priorityOptionConstant";
import UserSelect from '@/layouts/components/UserSelect.vue'

export default {
  name: "TaskDetailsView",
  components: {
    UserSelect,
    BLink,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCol,
    BRow,
    BCardText,
    BSpinner,
    BFormTextarea,
    flatPickr,
    BAlert,
    BFormDatepicker,
    BTabs,
    BTab,
    BImg,
    BMedia,
    AppTimeline,
    AppTimelineItem,
    BAvatarGroup,
    BEmbed,
    BDropdownDivider,
    quillEditor,
    BCardCode,
    BButtonGroup,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  methods: {
    kFormatter,
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
      },

      //filters
      userIdOption: "",
      selectUserIdOption: [],
      taskIdOption: "",
      selectTaskIdOptions: [],
      subTaskIdOption: "",
      selectSubTaskIdOptions: [],
      nextPhaseChangeStartTime: [],
      milestoneBoardInfo: [],

      //activity log
      timeDurationLog: [],
      phaseChangeLog: [],
      priorityOptionConstants,

      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    this.loadItems();
  },

  methods: {
    async getBoardTimeDurationLog(params) {
      const milestoneTaskBoardId = this.$route.params.id;
      return await this.$api.get(
        `api/milestone-task-boards/${milestoneTaskBoardId}/time-duration`,
        {
          params: {
            filterTaskId: params.filterTaskId,
            filterSubTaskId: params.filterSubTaskId,
          },
        }
      );
    },
    async getMilestoneBoardInfo() {
      const milestoneTaskBoardId = this.$route.params.id;

      return await this.$api.get(
        `/api/milestone-task-boards/${milestoneTaskBoardId}?include=tasks.subTasks,team`
      );
    },

    filterBySubTask() {
      this.nextPhaseChangeStartTime = [];
      this.loadTimeDurationLog();
    },
    async getUserTaskInfo() {
      const milestoneTaskBoardId = this.$route.params.id;

      let userTaskInfo = {};
      if (this.userIdOption) {
        userTaskInfo = await this.$api.get(
          `/api/users/${this.userIdOption}/milestone-task-board/${milestoneTaskBoardId}/tasks`
        );
        this.selectTaskIdOptions = (userTaskInfo?.data?.data || []).map(
          (item) => {
            let name = `${item?.short_title} (${item?.title})`;
            return {
              id: item?.id,
              name: name,
            };
          }
        );
      } else {
        this.selectTaskIdOptions = (
          this.milestoneBoardInfo?.tasks?.data || []
        ).map((item) => {
          let name = `${item?.short_title} ( ${item?.title})`;
          return {
            id: item?.id,
            name: name,
          };
        });
      }

      this.taskIdOption = "";
      this.subTaskIdOption = "";
      this.phaseChangeLog = [];
    },
    filterByTask() {
      // this.userIdOption = this.userIdOption ? "" : this.userIdOption;
      this.nextPhaseChangeStartTime = [];
      this.loadTimeDurationLog();

      if (this.taskIdOption) {
        this.phaseChangeLog = [];
      }
      let selectedTask = (this.milestoneBoardInfo?.tasks?.data || []).find(
        (item) => item?.id == this.taskIdOption
      );

      this.subTaskIdOption = "";
      this.selectSubTaskIdOptions = (selectedTask?.subTasks?.data || []).map(
        (item) => {
          let name = `${item?.short_title} ( ${item?.title})`;
          return {
            id: item?.id,
            name: name,
          };
        }
      );
    },

    async loadTimeDurationLog() {
      try {
        const [timeDurationLog] = await Promise.all([
          this.getBoardTimeDurationLog({
            filterTaskId: this.taskIdOption,
            filterSubTaskId: this.subTaskIdOption,
          }),
        ]);

        this.phaseChangeLog = (timeDurationLog?.data?.data || []).filter(
          (item) =>
            item?.properties?.old?.phase_id !=
            item?.properties?.attributes?.phase_id
        );

        for (let i = 1; i < this.phaseChangeLog.length; i++) {
          this.nextPhaseChangeStartTime.push(this.phaseChangeLog[i].created_at);
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    async loadItems() {
      // board activity_log/history
      try {
        const [milestoneBoardInfo] = await Promise.all([
          this.getMilestoneBoardInfo(),
        ]);

        this.milestoneBoardInfo = milestoneBoardInfo?.data?.data;

        this.selectUserIdOption = (
          this.milestoneBoardInfo?.team?.data || []
        ).map((item) => {
          return {
            avatar: item?.avatar,
            name: item?.name,
            email: item?.email,
            mobile: item?.mobile,
            employee_number: item?.employee_number,
            id: item?.id,
            departmentId: item?.department_id,
          }
        });
        this.selectTaskIdOptions = (
          this.milestoneBoardInfo?.tasks?.data || []
        ).map((item) => {
          let name = `${item?.short_title} ( ${item?.title})`;
          return {
            id: item?.id,
            name: name,
          };
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    formatLogDescription(createdAt, endedAt) {
      let description = "<b>On Going</b>";

      if (createdAt && endedAt) {
        const createdTime = this.$moment(createdAt);
        const endedTime = this.$moment(endedAt);
        const duration = endedTime.diff(createdTime);
        const durationFormatted = this.$moment.duration(duration);

        const hours = durationFormatted.hours();
        const minutes = durationFormatted.minutes();
        const seconds = durationFormatted.seconds();

        description = "<b> Phase Duration: ";
        if (hours) {
          description = description + `${hours} hours`;
        }

        if (minutes) {
          description = description + ` ${minutes} minutes`;
        }

        if (seconds) {
          description = description + ` ${seconds} seconds </b>`;
        }
      }

      return description;

      // return description;
    },
    formatLogTitle(value) {
      let logTitle = "";

      if (value?.event === "updated") {
        if (value?.log_name == "tasks") {
          if (
            value?.properties?.old?.phase_id !=
            value?.properties?.attributes?.phase_id
          ) {
            logTitle = `Task Moved To <span class="font-weight-bolder text-warning">${value?.properties?.attributes?.phase?.title}  </span> Phase`;
          }
        }
        if (value?.log_name == "sub_tasks") {
          if (
            value?.properties?.old?.phase_id !=
            value?.properties?.attributes?.phase_id
          ) {
            logTitle = `Sub Task Moved To <span class="font-weight-bolder text-warning">${value?.properties?.attributes?.phase?.title}  </span> Phase`;
          }
        }
      } else if (value?.event === "deleted") {
        if (value?.log_name == "tasks") {
          logTitle = `Task  <span class="font-weight-bolder text-danger">${value?.properties?.old?.title} </span> was moved to Trash `;
        }
      }

      if (value?.log_name === "default") {
        if (value?.description == "Task Team Assign Activity Logged") {
          logTitle = `Task <span class="font-weight-bolder text-warning"> Assigned Member </span> was updated for <span class="font-weight-bolder text-primary"> ${value?.properties?.task?.title} </span> Task`;
        }
      }
      //   if (value?.description == "Sub Task Team Assign Activity Logged") {
      //     logTitle = `Sub Task <span class="font-weight-bolder text-warning"> Assigned Member </span> was updated for <span class="font-weight-bolder text-primary"> ${value?.properties?.subtask?.title} </span> Sub Task`;
      //   }
      // }

      return logTitle;
    },
    setLogVariant(value) {
      const logColors = {
        updated: "warning",
        deleted: "danger",
      };
      return logColors[value?.event] || "warning";
    },
    formatDateTime(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YY, h:mm:ss a");
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";

.custom-font {
  font-size: 11px !important;
}

.label-margin {
  margin-top: 8px;
  margin-right: 8px;
}

.table-custom-style {
  font-size: 13px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}
.custom-font {
  font-size: 13px !important;
}

.file-input {
  display: inline-block;
  position: relative;
}

.attach-icon {
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.uploaded-files {
  margin-top: 20px;
}

.file-preview {
  display: inline-block;
  margin-right: 10px;
}
.file-preview .bigger-icon {
  font-size: 50px;
  color: #999;
}
.button-padding {
  padding-bottom: 15px;
}
</style>
