var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "custom-font",
    attrs: {
      "options": _vm.selectUserIdOption,
      "reduce": function reduce(option) {
        return option.id;
      },
      "placeholder": "Select Board Member",
      "label": "name"
    },
    on: {
      "input": _vm.getUserTaskInfo
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('UserSelect', {
          attrs: {
            "user": data
          }
        })];
      }
    }]),
    model: {
      value: _vm.userIdOption,
      callback: function callback($$v) {
        _vm.userIdOption = $$v;
      },
      expression: "userIdOption"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "custom-font",
    attrs: {
      "options": _vm.selectTaskIdOptions,
      "reduce": function reduce(option) {
        return option.id;
      },
      "placeholder": "Select Task",
      "label": "name"
    },
    on: {
      "input": _vm.filterByTask
    },
    model: {
      value: _vm.taskIdOption,
      callback: function callback($$v) {
        _vm.taskIdOption = $$v;
      },
      expression: "taskIdOption"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "custom-font",
    attrs: {
      "options": _vm.selectSubTaskIdOptions,
      "reduce": function reduce(option) {
        return option.id;
      },
      "placeholder": "Select Sub Task",
      "label": "name"
    },
    on: {
      "input": _vm.filterBySubTask
    },
    model: {
      value: _vm.subTaskIdOption,
      callback: function callback($$v) {
        _vm.subTaskIdOption = $$v;
      },
      expression: "subTaskIdOption"
    }
  })], 1)], 1)], 1)], 1)]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Phase Duration Log"
    }
  }, [_vm.phaseChangeLog.length > 0 ? [_c('app-timeline', _vm._l(_vm.phaseChangeLog, function (log, index) {
    return _c('app-timeline-item', {
      key: index,
      attrs: {
        "variant": _vm.setLogVariant(log)
      }
    }, [_c('div', {
      staticClass: "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
    }, [_c('h6', {
      domProps: {
        "innerHTML": _vm._s(_vm.formatLogTitle(log))
      }
    }), _c('small', {
      staticClass: "text-muted"
    }, [_vm._v(_vm._s(_vm.formatDateTime(log === null || log === void 0 ? void 0 : log.created_at)))])]), _c('p', {
      domProps: {
        "innerHTML": _vm._s(_vm.formatLogDescription(log === null || log === void 0 ? void 0 : log.created_at, _vm.nextPhaseChangeStartTime[index]))
      }
    })]);
  }), 1)] : [_c('app-timeline', {
    attrs: {
      "variant": "primary"
    }
  }, [_c('app-timeline-item', [_c('div', {
    staticClass: "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
  }, [_c('h6', [_vm._v("No Task or Sub Task Filters Selected")])])])], 1)]], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }